import React, { useEffect, useState } from "react";
//import teamMembers from "data/App/team.json";
//import directors from "data/App/directors.json";
import axios from "axios";
import ModalVideo from "react-modal-video";
import { Link } from "gatsby";
import {
  Modal,
  ModalCancelButton,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "react-beautiful-modals";

const Team = () => {
  const [director, setDirector] = useState([]);
  const [manage, setManage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(undefined);
  const [user, setUser] = useState(undefined);
  const [isOpen, setOpen] = useState(false);
  const [isOpen1, setOpen1] = useState(false);

  const openDirector = (e) => {
    setOpen1(true);
  };

  const openManger = (e) => {
    setOpen(true);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await axios
        .get("https://blog.mutualtrustmfb.com/director/")
        .then((response) => {
          const res = setDirector(response.data);
          console.log(res);
        });

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await axios
        .get("https://blog.mutualtrustmfb.com/management/")
        .then((response) => {
          const res = setManage(response.data);
          console.log(res);
        });

      setLoading(false);
    })();
  }, []);

  return (
    <section className="team section-padding style-6">
      <div className="content">
        <div className="container">
          {profile && (
            <Modal open={isOpen1} onClose={() => setOpen1(false)} size="md">
              <ModalHeader>
                <ModalTitle>{profile.name}</ModalTitle>
              </ModalHeader>
              <ModalDescription>
                <div
                  dangerouslySetInnerHTML={{ __html: `${profile.Details}` }}
                />
              </ModalDescription>
              <ModalFooter>
                <ModalCancelButton onClose={() => setOpen1(false)}>
                  Cancel
                </ModalCancelButton>
              </ModalFooter>
            </Modal>
          )}

          <div className="section-head text-center style-4 mb-60">
            <br />
            <h2 className="mb-20">
              Board of <span>Directors</span>{" "}
            </h2>
          </div>

          <div className="row">
            {director.map((member, index) => (
              <div className="col-lg-6 mb-20" key={index}>
                <div
                  className={` member ${
                    index !== director.length - 1 ? "mb-40 mb-lg-0" : ""
                  } style-4`}
                  onClick={() => {
                    setProfile(member);
                    openDirector();
                  }}
                >
                  <div className="img img-cover">
                    <img
                      src={member.image}
                      alt=""
                      style={{ height: 550, width: "100%" }}
                    />
                  </div>
                  <div className="pt-10">
                    <h6>{member.name}</h6>
                    <small>{member.position}</small>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <br />
          <div className="section-head text-center style-4 mb-60">
            <h2 className="mb-20">
              Management <span>Team</span>{" "}
            </h2>
          </div>
          {user && (
            <Modal open={isOpen} onClose={() => setOpen(false)} size="lg">
              <ModalHeader>
                <ModalTitle>{user.name}</ModalTitle>
              </ModalHeader>
              <ModalDescription>
                <div dangerouslySetInnerHTML={{ __html: `${user.Details}` }} />
              </ModalDescription>
              <ModalFooter>
                <ModalCancelButton onClose={() => setOpen(false)}>
                  Cancel
                </ModalCancelButton>
              </ModalFooter>
            </Modal>
          )}
          <div className="row">
            {manage.map((member, index) => (
              <div className="col-lg-4 mb-20" key={index}>
                <div
                  className={`member ${
                    index !== manage.length - 1 ? "mb-20 mb-lg-0 " : ""
                  } style-4`}
                  onClick={() => {
                    setUser(member);
                    openManger();
                  }}
                >
                  <div className="img img-cover">
                    <img
                      src={member.image}
                      alt=""
                      style={{ height: 350, width: "100%" }}
                    />
                  </div>
                  <div className="pt-10">
                    <h6>{member.name}</h6>
                    <small>{member.position}</small>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
